import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/howard-stricker.jpg";
  const fullName = `Howard Stricker`;
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const zoomMeetingPasscode = "";
  const birthDate = new Date(1935, 3 - 1, 25);
  const deathDate = new Date(2021, 1 - 1, 22);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 28);
  const memorialTime = "5pm EST";
  const inviteText = `Please join us in remembering and mourning Howard. With his passing, the world lost one of its most vibrant and colorful souls. Due to the current challenges we all face and restrictions in New Jersey, in lieu of a physical ceremony, Howard’s children invite you to attend a virtual service honoring his life.`;
  const inviteChild = <></>;
  const obituary = `Howard Stricker, doctor, father, partner and friend, died on Friday, January 22nd at his home in New Jersey. He is survived by his five children, Barbara, Dana, David, Meredith and Jillian, and his four grand-children, Alexis, Denae, Ariel and Mia, who will miss him forever. Howard served the community of Fairview, New Jersey for over fifty years as a physician. He helped generations of patients through illnesses, through aches and pains, through births and deaths. He was well loved, and will always be remembered.`;
  const storiesRecordUrl = "https://stories.afterword.com/fihafh72c";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rjyavbzwoqlvssybplqpu87nnxd1nrnn81ptwu59";
  const recordingEmbedDirectUrl = [
    {
      poster: "/images/people/howard-stricker-intro.png",
      url: "https://storage.googleapis.com/afterword-static/recordings/Howard%20Stricker%20-%20Recording.mp4",
    },
  ];
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
    >
      <br />
      <p>
        <i>
          "Death is but crossing the world, as friends do the seas; they live in
          one another still. For they must needs be present, that love and live
          in that which is omnipresent. In this divine glass they see face to
          face; and their converse is free, as well as pure. This is the comfort
          of friends, that though they may be said to die, yet their friendship
          and society are, in the best sense, ever present, because immortal.
        </i>
        <br /> <br />~ William Penn, More Fruits of Solitude
      </p>
    </StoryPage>
  );
};

export default ThisPage;
